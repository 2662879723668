"use client";
import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";

import { sendFormConversion } from "@rimo/frontend/services/conversion/Conversion";
import { createPardotUrlWithUtmParameters } from "@rimo/frontend/utils/utmParams";
import { Body, Header3 } from "@rimo/ui-old";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

export interface RequestMaterialProps {
  materialName?: string;
  formTitle?: string;
  hideTitle?: boolean;
}

export const RequestMaterialForm: React.FC<RequestMaterialProps> = (props) => {
  const { customT } = useClientTranslation();
  const materialName = props.materialName ?? "資料請求";
  const formTitle = props.formTitle ?? `${materialName}フォーム`;
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const receiveMessage = (event: MessageEvent) => {
      if (event.data !== "form_submitted") {
        return;
      }
      setSubmitted(true);
      sendFormConversion();
    };
    window.addEventListener("message", receiveMessage);
    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, []);

  const pardotUrl = createPardotUrlWithUtmParameters();

  return (
    <FormSection id="request-form">
      <PardotWrapper id="request-form">
        {submitted ? (
          <>
            <Lead>{customT("ご登録ありがとうございます。")}</Lead>
            <SubLead>
              {customT("メールにて詳細な情報を送付いたしますのでご確認ください。")}

              <br />
              {customT("もししばらく経ってもメールが届かない場合はお手数ですが再度フォームのご記入をお願い致します。")}

              <br />
              <br />
              <Header3 style={{ marginBottom: "8px" }}>
                {customT("サービス概要の説明を実際のデモ画面を用いて30分程度で実施しております")}
              </Header3>
              {customT("ぜひ下記空きスケジュールよりご都合のよろしい日時をご選択いただき、お申し込みください。")}
            </SubLead>
            <SpirWidget
              className="spir-widget"
              data-url="https://app.spirinc.com/external/teams/eIsKvaPHl1e6nv6Nsh19-/availability-sharings/5XQSEzxjbGWtYcJdzpjg6/confirm"
            ></SpirWidget>
            <script type="text/javascript" src="https://app.spirinc.com/js/external/iframe.js" async></script>
          </>
        ) : (
          <>
            {!props.hideTitle && <Lead>{customT(formTitle)}</Lead>}
            <SubLead>
              {customT("{{materialName}}をご希望の方は下記フォームをご記入ください", {
                materialName: customT(materialName).toLowerCase(),
              })}
            </SubLead>
            {pardotUrl && (
              <iframe
                title="request-form"
                src={pardotUrl}
                width="80%"
                height="900"
                frameBorder="0"
                allowTransparency={true}
                style={{ border: 0, margin: "40px auto 0", display: "block" }}
              ></iframe>
            )}
          </>
        )}
      </PardotWrapper>
    </FormSection>
  );
};

const FormSection = styled.div`
  background: rgba(255, 255, 255, 0.5);
  padding: 64px 0;
`;
const Lead = styled.h1`
  font-size: 40px;
  line-height: 64px;
  font-weight: 700;
  text-align: center;
  color: rgba(0, 0, 0, 0.84);
`;
const SubLead = styled(Body)`
  text-align: center;
  margin-top: 16px;
`;
const PardotWrapper = styled.div`
  max-width: 720px;
  margin: 0 auto;
`;
const SpirWidget = styled.div`
  min-width: 320px;
  max-width: 600px;
  margin: 8px auto 0;
  height: 650px;
  border: solid 1px ${(p) => p.theme.vars.palette.divider};
`;
